<script>
import axios from 'axios'

export default {
  props: {
    reserveData: Object
  },
  data() {
    return {
      message: '',
      snackbarFlag: false,
      snackbarTimeout: 3000,
      snackbarColor: 'success',
      snackbarText: '',
      loading: false,
      success: false,
      required: value => !!value || "必ず入力してください"
    }
  },
  methods: {
    close() {
      this.message = ''
      this.$emit('clickClose')
    },
    submit() {
      var me = this
      if (this.$refs.send_data.validate()) {
        this.success = true
        this.loading = true

        const publicKeyStr = process.env.VUE_APP_CRYPTO_PUBLIC_KEY.replace(/\\n/g, '\n')
        const publicKey = KEYUTIL.getKey(publicKeyStr)

        const headerName = KJUR.crypto.Cipher.encrypt(process.env.VUE_APP_HEADER_NAME, publicKey, 'RSA')

        const params = new URLSearchParams()
        params.append('uuid', this.reserveData.uuid)
        params.append('message', this.message)

        axios.post(process.env.VUE_APP_SEND_LINE_API, params, {
          headers: {
            'X-Line-Signature': headerName
          }
        })
          .then(response => {
            me.loading = false

            if (response.status == 200) {
              me.showSnackbar('送信完了しました', 'success')
            } else {
              me.showSnackbar('送信に失敗しました', 'error')

            }

          }).catch(error => {
            console.log(error);
            me.loading = false
            me.showSnackbar('送信に失敗しました', 'error')
        });
      } else {
        this.success = false
      }
    },
    showSnackbar(text, color) {
      this.snackbarFlag = true
      this.snackbarColor = color
      this.snackbarText = text
    }
  }
}
</script>
<template>
  <v-card>
    <v-card-text>
      <v-card-text>{{reserveData.name}}様へLINEを送信</v-card-text>
      <v-form ref="send_data">
        <v-textarea v-model="message" label="メッセージ" outlined :rules="[required]" :disabled="loading"/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close" :disabled="loading">
            閉じる
          </v-btn>
          <v-btn color="success" @click="submit" :loading="loading">
            送信する
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
    <!-- Snackbar -->
    <div>
      <v-snackbar
        v-model="snackbarFlag"
        :color="snackbarColor"
        :timeout="snackbarTimeout"
        :multi-line="true"
        top
      >
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackbarFlag = false"
          >
            <v-icon
            right
            >
            mdi-close-circle-outline
          </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-card>
</template>